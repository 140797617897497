'use client';

import { useLocalStorage } from '@xyla/util';
import { useDetectAdBlock } from 'adblock-detect-react';
import Cookies from 'js-cookie';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  MixpanelEventClientSuperProps,
  MixpanelEventName,
  MixpanelEventProps,
  TrackMixpanelEvent,
} from '../mixpanel-events.interface';
import { parseLocalStorageRecord } from '../utils/externalIdentifierRecordUtils';

const MIXPANEL_ENABLED =
  process.env.NEXT_PUBLIC_ENABLE_MIXPANEL_ANALYTICS === 'true';

// Config for the session cookie that stores super props
export const MIXPANEL_SESSION_COOKIE_KEY = 'xyla_device_id';
const MIXPANEL_SESSION_COOKIE_OPTIONS = {
  sameSite: 'Lax' as const,
};

// Cookies names. Currently set so that we can use this info server-side.
// Useful for users that aren't logged in.
export const AUTH0_COOKIE_NAME = 'auth0';
export const DGID_COOKIE_NAME = 'dgid';
export const EID_COOKIE_NAME = 'eid';
export const NEID_COOKIE_NAME = 'neid';
export const DGID_NPI_COOKIE_NAME = 'dgidNPI';

const setAuth0Cookie = (value: Auth0Info) => {
  Cookies.set(AUTH0_COOKIE_NAME, JSON.stringify(value), { sameSite: 'Lax' });
};

const getAuth0Cookie = () => {
  try {
    return JSON.parse(Cookies.get(AUTH0_COOKIE_NAME) || 'null');
  } catch {}
};

const setDgidCookie = (value: string) => {
  Cookies.set(DGID_COOKIE_NAME, value, { sameSite: 'Lax' });
};

const getDgidCookie = () => {
  return Cookies.get(DGID_COOKIE_NAME);
};

const setDgidNPICookie = (value: string) => {
  Cookies.set(DGID_NPI_COOKIE_NAME, value, { sameSite: 'Lax' });
};

const getDgidNPICookie = () => {
  return Cookies.get(DGID_NPI_COOKIE_NAME);
};

const setEidCookie = (value: string) => {
  Cookies.set(EID_COOKIE_NAME, value, { sameSite: 'Lax' });
};

const getEidCookie = () => {
  return Cookies.get(EID_COOKIE_NAME);
};

const setNeidCookie = (value: string) => {
  Cookies.set(NEID_COOKIE_NAME, value, { sameSite: 'Lax' });
};

const getNeidCookie = () => {
  return Cookies.get(NEID_COOKIE_NAME);
};

const setDeviceId = (value: string) => {
  Cookies.set(
    MIXPANEL_SESSION_COOKIE_KEY,
    value,
    MIXPANEL_SESSION_COOKIE_OPTIONS
  );
};

export const getDeviceId = () => {
  const deviceIdFromCookie = Cookies.get(MIXPANEL_SESSION_COOKIE_KEY);
  if (deviceIdFromCookie) {
    return deviceIdFromCookie;
  } else {
    const newDeviceId = uuidv4();
    setDeviceId(newDeviceId);
    return newDeviceId;
  }
};

export const generateNewDeviceId = () => {
  setDeviceId(uuidv4());
};

export interface Auth0Info {
  auth0Id: string;
  auth0NPI: string; // deprecated
  auth0Occupation: string;
  auth0HasPhysicianRole: boolean; // deprecated
  auth0NPIFromDB: string;
  auth0AccessLevel: string;
  auth0RegistrationType: string;
  auth0HCPIdentifierType: string;
  auth0HCPIdentifierValue: string;
}

interface ExternalTrackingContextType {
  /** Sends an event to Mixpanel. The request is routed to a Next.js server proxy first. */
  trackMixpanelEvent: TrackMixpanelEvent;
  /** Unique ID from IQVIA indicating that this user is NPI-tagged */
  dgid?: string;
  /** Should be called when IQVIA tracking identifies the user */
  setDgid: (value: string) => void;

  /** NPI from IQVIA */
  dgidNPI?: string;
  /** Should be called when IQVIA tracking identifies the user */
  setDgidNPI: (value: string) => void;

  /** Email ID, a hash of a user's email where we know the user is an NPI */
  eid?: string;
  /** Should be called when a user who clicked on a link from an email lands on our site */
  setEid: (value: string) => void;

  /** Email ID, a hash of a user's email where we know the user is not an NPI*/
  neid?: string;
  /** Should be called when a user who clicked on a link from an email lands on our site */
  setNeid: (value: string) => void;

  /** Twitter ID, an id that represents the twitter user */
  twclid?: string;
  /** Should be called when a user who clicked on a link from twitter lands on our site */
  setTwclid: (value: string) => void;

  /** Auth0 Info */
  auth0Info?: Auth0Info;
  /** Should be called when a user logs in */
  setAuth0Info: (value: Auth0Info) => void;

  /** Mailing list emails */
  mailingListEmails: string[];
  /** Should be called when a user subscribes to the mailing list */
  setMailingListEmails: (value: string[]) => void;

  /** Marketing campaigns engaged */
  marketingSourcesEngaged: string[];
  setMarketingSourcesEngaged: (value: string[]) => void;
  marketingCampaignsEngaged: string[];
  setMarketingCampaignsEngaged: (value: string[]) => void;
}

const ExternalTrackingContext = createContext<ExternalTrackingContextType>({
  trackMixpanelEvent: (() => {}) as any,
  dgid: undefined,
  setDgid: () => {},
  dgidNPI: undefined,
  setDgidNPI: () => {},
  eid: undefined,
  setEid: () => {},
  neid: undefined,
  setNeid: () => {},
  twclid: undefined,
  setTwclid: () => {},
  auth0Info: undefined,
  setAuth0Info: () => {},
  mailingListEmails: [],
  setMailingListEmails: () => {},
  marketingSourcesEngaged: [],
  setMarketingSourcesEngaged: () => {},
  marketingCampaignsEngaged: [],
  setMarketingCampaignsEngaged: () => {},
});

export function useExternalTracking(): ExternalTrackingContextType {
  return useContext(ExternalTrackingContext);
}

/**
 * Provides a context that enables sending events to Mixpanel and manages user
 * ID's from external tracking systems, e.g. IQVIA.
 *
 * 1. Exposes the `trackMixpanelEvent` function and inserts any necessary
 * client-side super properties, which are tracked with every event.
 * 2. Manages a session cookie with the Mixpanel `$device_id`, a random UUID
 * used to track users who are not logged in.
 * 3. Manages get/set for `eid` and `dgid` into localStorage.
 */
export function ExternalTrackingProvider({
  children,
}: {
  children?: ReactNode;
}) {
  // For backwards-compatibility, we have to do some extra parsing when reading
  // these values from localStorage.
  const [localDgid, setDgid] = useLocalStorage<string | undefined>(
    'dgid',
    undefined
  );
  const [localEid, setEid] = useLocalStorage<string | undefined>(
    'eid',
    undefined
  );
  const [localNeid, setNeid] = useLocalStorage<string | undefined>(
    'neid',
    undefined
  );
  const dgid = useMemo(() => parseLocalStorageRecord(localDgid), [localDgid]);
  const eid = useMemo(() => parseLocalStorageRecord(localEid), [localEid]);
  const neid = useMemo(() => parseLocalStorageRecord(localNeid), [localNeid]);

  const [twclid, setTwclid] = useLocalStorage<string | undefined>(
    'twclid',
    undefined
  );

  // Save the DGID NPI because not all DGIDs have NPIs. Sending this to Mixpanel allows us to get a more accurate sense of
  // physician actions.
  const [dgidNPI, setDgidNPI] = useLocalStorage<string | undefined>(
    'dgidNPI',
    undefined
  );

  // Saved in local storage to keep track of past logins
  // ID is used for offline analytics and model training, NPI/occupation are used for online NPI-based metric computation
  const [auth0Info, setAuth0Info] = useLocalStorage<Auth0Info | undefined>(
    'auth0Info2',
    undefined
  );

  const [mailingListEmails, setMailingListEmails] = useLocalStorage<string[]>(
    'mailingListEmails',
    []
  );

  // Save marketing campaigns and sources engaged, makes it easier to do analytics
  const [marketingSourcesEngaged, setMarketingSourcesEngaged] = useLocalStorage<
    string[]
  >('mse', []);

  const [marketingCampaignsEngaged, setMarketingCampaignsEngaged] =
    useLocalStorage<string[]>('mce', []);

  // Keep track of if the most authoritative identification is auth0 or eid
  const [mostAuthoritativeIdentification, setMostAuthoritativeIdentification] =
    useLocalStorage<'auth0' | 'eid' | undefined>(
      'mostAuthoritativeIdentification',
      undefined
    );

  // Identify if the user has an ad-blocker enabled
  const adBlockDetected = useDetectAdBlock();

  const trackMixpanelEvent = useCallback(
    async (eventName: MixpanelEventName, eventProps: MixpanelEventProps) => {
      if (!MIXPANEL_ENABLED || typeof window === 'undefined') {
        return;
      }

      // Inject super properties into every event
      const location = window.location;
      const origin =
        location.protocol +
        '//' +
        location.hostname +
        (location.port ? ':' + location.port : '');
      const currentUrl = `${origin}${location.pathname}`;
      const propsWithSuperProps = {
        $current_url: currentUrl,
        $device_id: getDeviceId(),
        dgid,
        dgidNPI,
        eid,
        neid,
        twclid,
        ...auth0Info,
        mailingListEmails,
        marketingSourcesEngaged,
        marketingCampaignsEngaged,
        mostAuthoritativeIdentification,
        adBlockDetected,
        ...eventProps, // Make sure the eventProps are last so they can override the super props
      } satisfies MixpanelEventClientSuperProps;

      // POST the event to Next.js server proxy
      const payload = {
        event_name: eventName,
        event_props: propsWithSuperProps,
      };
      try {
        const response = await fetch('/api/events', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });
        return response;
      } catch (err) {
        console.error(err);
      }
    },
    [
      dgid,
      dgidNPI,
      eid,
      neid,
      twclid,
      auth0Info,
      mailingListEmails,
      marketingSourcesEngaged,
      marketingCampaignsEngaged,
      mostAuthoritativeIdentification,
      adBlockDetected,
    ]
  );

  const setEidWrapper = useCallback(
    (value: string) => {
      setEid(value);
      setMostAuthoritativeIdentification('eid');

      // Set cookies so the server functions can access this information
      setEidCookie(value);
    },
    [setEid, setMostAuthoritativeIdentification]
  );

  const setNeidWrapper = useCallback(
    (value: string) => {
      setNeid(value);

      // Set cookies so the server functions can access this information
      setNeidCookie(value);
    },
    [setNeid]
  );

  const setAuth0InfoWrapper = useCallback(
    (value: Auth0Info) => {
      setAuth0Info(value);
      setMostAuthoritativeIdentification('auth0');

      // Set cookies so user is still tagged even when logged out.
      setAuth0Cookie(value);
    },
    [setAuth0Info, setMostAuthoritativeIdentification]
  );

  const setDgidWrapper = useCallback(
    (value: string) => {
      setDgid(value);

      // Set cookies so the server functions can access this information
      setDgidCookie(value);
    },
    [setDgid]
  );

  // Set the cookies if they are not already set. At the very least this is needed
  // because there are a bunch of users with these values set in local storage but not cookies
  // but it's also good just to ensure sync between the two.
  useEffect(() => {
    if (auth0Info && getAuth0Cookie() !== auth0Info) {
      setAuth0Cookie(auth0Info);
    }
  }, [auth0Info]);

  useEffect(() => {
    if (dgid && getDgidCookie() !== dgid) {
      setDgidCookie(dgid);
    }
  }, [dgid]);

  useEffect(() => {
    if (dgidNPI && getDgidNPICookie() !== dgidNPI) {
      setDgidNPICookie(dgidNPI);
    }
  }, [dgidNPI]);

  useEffect(() => {
    if (eid && getEidCookie() !== eid) {
      setEidCookie(eid);
    }
  }, [eid]);

  useEffect(() => {
    if (neid && getNeidCookie() !== neid) {
      setNeidCookie(neid);
    }
  }, [neid]);

  const contextValue = useMemo(
    () =>
      ({
        trackMixpanelEvent,
        dgid,
        setDgid: setDgidWrapper,
        dgidNPI,
        setDgidNPI,
        eid,
        setEid: setEidWrapper,
        neid,
        setNeid: setNeidWrapper,
        twclid,
        setTwclid,
        auth0Info,
        setAuth0Info: setAuth0InfoWrapper,
        mailingListEmails,
        setMailingListEmails,
        marketingSourcesEngaged,
        setMarketingSourcesEngaged,
        marketingCampaignsEngaged,
        setMarketingCampaignsEngaged,
      } satisfies ExternalTrackingContextType),
    [
      trackMixpanelEvent,
      dgid,
      setDgidWrapper,
      dgidNPI,
      setDgidNPI,
      eid,
      setEidWrapper,
      neid,
      setNeidWrapper,
      twclid,
      setTwclid,
      auth0Info,
      setAuth0InfoWrapper,
      mailingListEmails,
      setMailingListEmails,
      marketingSourcesEngaged,
      setMarketingSourcesEngaged,
      marketingCampaignsEngaged,
      setMarketingCampaignsEngaged,
    ]
  );

  return (
    <ExternalTrackingContext.Provider value={contextValue}>
      {children}
    </ExternalTrackingContext.Provider>
  );
}
